













import { NavigationItem, PhotoCollectionObject } from "@/interfaces/models";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const navigation = namespace("navigation");

@Component
export default class PhotoCollectionNav extends Vue {
  @navigation.Getter currentNavigation: any;
  @Prop({ required: true }) collections?: PhotoCollectionObject[];
  @Prop({ required: true }) name?: string;
  @Prop({ default: "white" }) color?: string;
  activeIndex = 0;
  visible = false;

  get host() {
    return this.$root.$data.host;
  }

  get images(): { title: string; src: string }[] {
    const images = this.collections?.map((item: PhotoCollectionObject) => {
      return {
        title: item.photo_collection.data.attributes.name,
        src:
          this.$root.$data.host +
          item.photo_collection.data.attributes.thumbnail.data.attributes.url
      };
    });
    return images ?? [];
  }

  navTo(collection: PhotoCollectionObject): void {
    const pages: NavigationItem[] = Object.values(this.currentNavigation.pages);
    const page: any = pages.find(
      (item: NavigationItem) => item.related.id === collection.page.data.id
    );
    if (page) {
      this.$router.push(page.path);
    }
  }
}
